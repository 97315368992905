import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './agreement-management/components/login/login.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {path:'', loadChildren:()=>import('../app/agreement-management/agreement-management.module').then(m=>m.AgreementManagementModule)},
  {path:'login',component:LoginComponent , canActivate:[MsalGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
