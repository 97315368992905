
export const environment = {
  production: true,
  clientId:"fcb33c79-f7f7-4e24-863a-f56d5d98191a",
  authority:"https://login.microsoftonline.com/8db90014-ca66-493d-b1b1-aa9ce0ba9aed/",
  scopeUri:'api://a93a3fb4-3a73-4340-8552-48cc59e56965/AgreementManagementProdWeb-Api-Access',
  redirectUrl:"https://customeragreement.fbmsales.com/login/", //Dev env  
  postLogoutRedirectUri:"https://agreementmanagementprodwebapp.azurewebsites.net/", //Dev env
  // apiURL:'https://localhost:7058/api/',
  apiURL:"https://agreementmanagementprodwebapi.azurewebsites.net/api/",
  isNativeLogin: false,
  groupId:"dc116629-b47a-4440-80d4-c585f896ca07",
  apiBaseURL: "https://agreementmanagementprodwebapi.azurewebsites.net"
 };