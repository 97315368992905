
export class UserInfo {
    email : string ;
    userName : string ;
    accessGroup: string ;
    assignedBranch : string ;
    userRole : string ;
    givenName!: string;

  constructor(email: string='', userName: string='',accessGroup:string='', assignedBranch: string='', userRole: string='') {
    this.email = email;
    this.userName = userName;
    this.accessGroup = accessGroup;
    this.assignedBranch = assignedBranch;
    this.userRole = userRole;
  }
}
