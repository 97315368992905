import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root'
})
export class AdUserService {
    apiUrl=environment.apiURL;
    constructor(
        private httpClient:HttpClient
    ){}
    
    getUsersInAdGroupByRole(role:string){
        let url = this.apiUrl+"ADUser/GetUsersInAdGroup?role="+role;
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        // let queryParams = new HttpParams();
        // queryParams = queryParams.append("role", role);
        return this.httpClient.post<any[]>(url,{headers : headers});
    }

    searchAduserByEmail(userEmail:string){
        return this.httpClient.get(this.apiUrl + 'ADUser/SearchADUserByEmail?emailId='+userEmail);
    }
 
}