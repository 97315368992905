import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { SpinnerComponent } from "../shared/spinner/spinner.component";
import { Subject, map, scan } from "rxjs";
import Swal from 'sweetalert2';
@Injectable({
    providedIn:'root'
})
export class SpinnerService{

    spin$ : Subject<boolean> = new Subject();

    constructor(private overlay:Overlay){
        this.spin$
      .asObservable()
      .pipe(
        map(val => val ? 1 : -1 ),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
      )
      .subscribe(
        (res) => {
          if(res === 1){ this.showSpinner() }
          else if( res == 0 ){ 
            this.spinnerRef.hasAttached() ? this.stopSpinner(): null;
          }
        }
      )
    }
    private spinnerRef=this.cdkCreateSpinner();

    private cdkCreateSpinner(){
        return this.overlay.create({
            hasBackdrop:true,
            backdropClass:'dark-backdrop',
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
        })
    }

    showSpinner(){
        this.spinnerRef.attach(new ComponentPortal(SpinnerComponent));
    }
    stopSpinner(){
        this.spinnerRef.detach();
    }

    showLoader() {
      Swal.fire({
        title: 'Please wait...',
        text: 'Processing your request',
        allowOutsideClick: false,  // Prevent closing by clicking outside
        didOpen: () => {
          Swal.showLoading(); // Start showing the loader
        }
      });
    }

        // Close Loader
    closeLoader() {
      Swal.close(); // Close the Swal loader
    }
}