<script src="./environments/environment.ts"></script>
<mat-toolbar color="info" class="mat-elevation-z4">
  <mat-toolbar-row>
    <span class="logo-img"><img src="./assets/img/FBMLogo.PNG"></span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-drawer-container>
  <!-- main content -->
  <mat-drawer-content>
    <div class="" style="margin-top: 6%;  margin-left: 30%; width: 30%; height: 30%;">
      <mat-toolbar class="header-bg-color">
        <span class="header-text-color">Login</span>
      </mat-toolbar>
      <mat-card style="height: 100%;">
        <mat-card-content>
          <form [formGroup]="loginForm" *ngIf= "IsNativeLogin" class="example-form" (ngSubmit)="onLogin()">
            <div>
              <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Username</mat-label>
                <input formControlName="userName" matInput placeholder="email" required>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Password</mat-label>
                <input formControlName="password" matInput type="password"  placeholder="password" required>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </div>          
            <button mat-raised-button [disabled]="!loginForm.valid" class="btn btn-info-color" type="submit" style="margin-right: 10px; width: 20%;">Login</button>          
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<!-- 
   [class.button-color]="!loginForm.valid"
 -->