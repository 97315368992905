import { Injectable } from '@angular/core';
import { UserInfo } from '../models/UserInfo';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  userInfoObj : UserInfo;
  userList: UserInfo[] = [];

  constructor() {
    this.userInfoObj = new UserInfo();
    this.userList = [];
      
   }
   setUserInfo(item:any) 
    {
      this.userInfoObj.userName = item[0].userName;
      this.userInfoObj.email = item[0].email;
      this.userInfoObj.accessGroup = item[0].accessGroup;
      this.userInfoObj.assignedBranch = 'All';
      this.userInfoObj.userRole = item[0].userRole;
    }
 
  getUserInfo(): UserInfo {
    return this.userInfoObj;
  }
  clearUserInfo(){
    this.userInfoObj = new UserInfo();
  }
}
