import { IPublicClientApplication, 
    PublicClientApplication, 
    InteractionType, 
    BrowserCacheLocation, 
    LogLevel } 
      from '@azure/msal-browser';

import { MsalInterceptorConfiguration, 
       MsalGuardConfiguration } from '@azure/msal-angular';
import {environment} from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read','group.read.all','GroupMember.Read.All'],);
  //protectedResourceMap.set(`${environment.redirectUrl}weatherforecast`, [`${environment.clientId}/.default`]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group', ['user.read','group.read.all','GroupMember.Read.All'],);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/groups', ['user.read','group.read.all','GroupMember.Read.All'],);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', ['user.read','group.read.all','GroupMember.Read.All'],);
  protectedResourceMap.set(`${environment.apiBaseURL}`, [`${environment.scopeUri}`],);
  
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read", "openid", "profile","group.read.all"]
      //scopes: ['api://e3f69ac7-305d-42c8-9137-61d25da2daa4/.default' ,'openid', 'offline_access']
    },
  };
}
